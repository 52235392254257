import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildLoadListFileGroupUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ListFileGroup } from '../types';

export const loadListFileGroup = (groupId: string): Observable<ListFileGroup> => {
    const obs = sendRetrieveRequest(buildLoadListFileGroupUrl(groupId));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ListFileGroup>) => {
            const listFileGroup = convertToCamel<ListFileGroup>(response);
            return observableOf(listFileGroup);
        }),
    );
};
